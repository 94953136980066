import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['item', 'toggler'];

  static classes = ['hidden'];

  get isShown() {
    return !this.itemTarget.classList.contains(this.hiddenClass);
  }

  // Actions

  toggle() {
    if (this.isShown) {
      this.hide();
    } else {
      this.show();
    }
  }

  show() {
    this.itemTargets.forEach((item) => {
      item.classList.remove(...this.hiddenClasses);
    });

    this.itemTarget.focus();

    this.togglerTargets.forEach((item) => {
      item.setAttribute('aria-expanded', true);
    });
  }

  hide() {
    this.itemTargets.forEach((item) => {
      item.classList.add(...this.hiddenClasses);
    });

    this.togglerTargets.forEach((item) => {
      item.setAttribute('aria-expanded', 'false');
    });
  }
}
